import { MdSend } from "react-icons/md";
import { useState } from "react";

export default function ChatInput({ disabled, onSend }) {
  const [message, setMessage] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true); // Initially show suggestions

  const suggestions = [
    "Tell me about DORA Compliance",
    "What is GDPR Regulations",
    "What is IFRS Regulations",
    "Tell me about AI Act",
  ];

  const handleSuggestionClick = (suggestion) => {
    onSend(suggestion);
    setMessage("");
    setShowSuggestions(false); // Hide suggestions after clicking one
  };

  return (
    <div className="justify-center flex ">
      <div
        className="w-full md:mx-24
      "
      >
        {showSuggestions &&
          message === "" && ( // Show suggestions only when there's no message
            <div className="grid  md:grid-cols-4 grid-cols-2 gap-2 md:gap-5 my-5">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="bg-slate-500 text-white p-2 rounded cursor-pointer hover:bg-pink-400"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}

        <div className="">
          <form
            className="border-2  w-full border-slate-400 rounded-lg flex flex-row  text-slate-50 py-2"
            onSubmit={(e) => {
              e.preventDefault();
              onSend(message);
              setMessage("");
              setShowSuggestions(false); // Hide suggestions after submitting the form
            }}
            autoComplete="off"
          >
            <input
              name="message"
              placeholder="What can I do for you today?"
              className={"w-full ml-4 bg-slate-700 focus:outline-none "}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setShowSuggestions(e.target.value === ""); // Show suggestions only when input is empty
              }}
            />
            <button
              disabled={disabled}
              className={
                "bg-slate-500 " +
                "text-white " +
                "font-bold " +
                "py-2 px-4 " +
                "rounded " +
                "hover:bg-pink-400 " +
                "disabled:bg-slate-600 " +
                "disabled:text-slate-400 mr-2"
              }
            >
              <MdSend />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
