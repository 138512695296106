import React from "react";

export default function Loading() {
  return (
    <div className="text-white mx-auto">
      <div className="flex items-center justify-center space-x-2">
        <Spinner animation="spin" />
        <Spinner animation="grow" />
      </div>
    </div>
  );
}

function Spinner({ animation }) {
  const spinnerClasses = {
    spin: "animate-spin border-4 border-solid border-current border-r-transparent",
    grow: "animate-[spinner-grow_0.75s_linear_infinite] bg-current opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]",
  };

  return (
    <div
      className={`inline-block h-12 w-12 rounded-full align-[-0.125em] ${spinnerClasses[animation]}`}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}
