export const createNewThread = async () => {
  try {
    let response = await fetch("http://13.39.86.168:8000/api/new", {
      method: "POST",
    });
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchThread = async (threadId) => {
  try {
    let response = await fetch(
      `http://13.39.86.168:8000/api/threads/${threadId}`
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchRun = async (threadId, runId) => {
  try {
    let response = await fetch(
      `http://13.39.86.168:8000/api/threads/${threadId}/runs/${runId}`
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const postMessage = async (threadId, message) => {
  try {
    let response = await fetch(
      `http://13.39.86.168:8000/api/threads/${threadId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: message }),
      }
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const postToolResponse = async (threadId, runId, toolResponses) => {
  try {
    let response = await fetch(
      `http://13.39.86.168:8000/api/threads/${threadId}/runs/${runId}/tool`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toolResponses),
      }
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

// New API endpoint for viewing thread history
export const fetchThreadHistory = async (threadId) => {
  try {
    let response = await fetch(
      `http://13.39.86.168:8000/api/threads/${threadId}/history`
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

// New API endpoint for uploading files
export const uploadFiles = async (files) => {
  try {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    let response = await fetch("http://13.39.86.168:8000/api/upload", {
      method: "POST",
      body: formData,
    });
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};
