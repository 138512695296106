import Markdown from "react-markdown";
import { SiOpenai } from "react-icons/si";
import remarkGfm from "remark-gfm";

export default function ChatMessage({ message, role }) {
  const roleIcon =
    role === "user" ? (
      <div className="rounded-full mt-1 h-8 w-8 bg-slate-600 flex items-center justify-center font-semibold text-slate-300 shrink-0">
        <img
          className="h-6 w-6"
          src="https://cdn-icons-png.flaticon.com/128/3237/3237472.png"
          alt=""
        />
      </div>
    ) : (
      <div className="rounded-full mt-1 h-8 w-8 flex items-center justify-center font-semibold text-slate-50 shrink-0">
        <img
          className="h-10 w-8"
          src="https://i.ibb.co/1rrkxH5/LOGO78.png"
          alt=""
        />
      </div>
    );

  const roleName = role === "user" ? "User" : "Chat-PLY";

  return (
    <div className="flex flex-col md:flex-row md:mx-24 my-4 ">
      {roleIcon}
      <div className="p-1 md:ml-2">
        <div className="flex-col">
          <p className="font-semibold text-slate-400">{roleName}</p>
          <Markdown
            className="text-slate-50 markdown"
            remarkPlugins={[remarkGfm]}
          >
            {message}
          </Markdown>
        </div>
      </div>
    </div>
  );
}
