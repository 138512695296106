import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import ChatInput from "./components/ChatInput";
import ChatMessage from "./components/ChatMessage";
import ChatVideoEmbedding from "./components/ChatVideoEmbedding";
import ChatStatusIndicator from "./components/ChatStatusIndicator";
import Loading from "./components/Loading";
import { useThread } from "./hooks/useThread";
import { useRunPolling } from "./hooks/useRunPolling";
import { useRunRequiredActionsProcessing } from "./hooks/useRunRequiredActionsProcessing";
import { useRunStatus } from "./hooks/useRunStatus";
import { postMessage } from "./services/api";

function App() {
  const [run, setRun] = useState(undefined);
  const { threadId, messages, setActionMessages, clearThread } = useThread(
    run,
    setRun
  );
  useRunPolling(threadId, run, setRun);
  useRunRequiredActionsProcessing(run, setRun, setActionMessages);
  const { status, processing } = useRunStatus(run);

  let messageList = messages
    .toReversed()
    .filter((message) => message.hidden !== true)
    .map((message) => {
      if (message.role === "video_embedding") {
        return <ChatVideoEmbedding url={message.content} key={message.id} />;
      } else {
        return (
          <ChatMessage
            message={message.content}
            role={message.role}
            key={message.id}
          />
        );
      }
    });

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 h-screen bg-slate-700 flex flex-col">
      <Header onNewChat={clearThread} />
      <div className="flex flex-col-reverse grow overflow-y-scroll">
        {status !== undefined && <ChatStatusIndicator status={status} />}
        <div>
          {" "}
          {processing && (
            <div className="ml-2 sm:ml-4 md:ml-8 lg:ml-16">
              <Loading />
            </div>
          )}
        </div>
        {messageList}
      </div>
      <div className="my-4">
        <ChatInput
          onSend={(message) => {
            postMessage(threadId, message).then(setRun);
          }}
          disabled={processing}
        />
      </div>
    </div>
  );
}

export default App;
